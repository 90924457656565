import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Share from "@material-ui/icons/Share";
import Bookmark from "@material-ui/icons/Bookmark";
import Book from "@material-ui/icons/Book";

interface MenuPagesProps {
  isOpen: boolean;
  anchorEl: HTMLElement;
  onClose: Function;
  isSharingAvailable: boolean;
  isShowBookmarkEntries: boolean;
  chapterNumber?: number;
  bookmarkClicked?: () => void;
  gotoBookmarkClicked?: () => void;
  shareVersesClicked?: () => void;
  onNewUrl: Function;
}

function MenuPages(props: MenuPagesProps) {
  function handlePagesMenuItemClick(event: React.MouseEvent<HTMLElement>) {
    let item = event.currentTarget.id.replace("pages-menu-", "");
    if (item.includes("http://") || item.includes("https://") || item === "/") {
      if (item.includes("helloquran") && props.chapterNumber != null) {
        window.location.href = item + `/${props.chapterNumber}`;
      } else if (item.includes("corequran") && props.chapterNumber != null) {
        window.location.href = item + `/${props.chapterNumber}`;
      } else {
        window.location.href = item;
      }
    } else {
      props.onNewUrl(item);
    }
  }

  return (
    <Menu
      id="pages-menu"
      anchorEl={props.anchorEl}
      open={props.isOpen}
      onClose={() => props.onClose(null)}
      //anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      //transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <MenuItem id="pages-menu-about" onClick={handlePagesMenuItemClick}>
        About
      </MenuItem>
      <MenuItem id="pages-menu-contact" onClick={handlePagesMenuItemClick}>
        Contact
      </MenuItem>
      <MenuItem id="pages-menu-https://www.quranwow.com" onClick={handlePagesMenuItemClick}>
        Quran Multilingual
      </MenuItem>
      <MenuItem id="pages-menu-https://www.corequran.com" onClick={handlePagesMenuItemClick}>
        Quran Arabic English
      </MenuItem>
      <MenuItem id="pages-menu-https://www.helloquran.com" onClick={handlePagesMenuItemClick}>
        Quran in English
      </MenuItem>
      <MenuItem id="pages-menu-https://www.quranpdfs.com" onClick={handlePagesMenuItemClick}>
        Quran PDFs
      </MenuItem>
      {props.isShowBookmarkEntries && (
        <div>
          <Divider />
          <MenuItem onClick={props.bookmarkClicked}>
            {" "}
            <ListItemIcon style={{ float: "right" }}>
              <Book fontSize="small" />
            </ListItemIcon>{" "}
            Bookmark Verse
          </MenuItem>
          <MenuItem onClick={props.gotoBookmarkClicked}>
            {" "}
            <ListItemIcon style={{ float: "right" }}>
              <Bookmark fontSize="small" />
            </ListItemIcon>{" "}
            Go To Bookmark
          </MenuItem>
        </div>
      )}
      {props.isSharingAvailable && (
        <div>
          <Divider />
          <MenuItem onClick={props.shareVersesClicked}>
            <ListItemIcon style={{ float: "right" }}>
              <Share fontSize="small" />
            </ListItemIcon>
            Share Ayahs
          </MenuItem>
        </div>
      )}
    </Menu>
  );
}
export default MenuPages;
